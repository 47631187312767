<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" label-width="96px">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="代理编号" prop="agentNo">
						<el-input v-model="queryParams.agentNo" clearable placeholder="请输入代理编号" size="small" @keyup.enter.native="handleQuery"  @blur="replaceComma"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="代理名称" prop="name">
						<el-input v-model="queryParams.userName" clearable placeholder="请输入代理名称" size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="联系人手机号" prop="name">
						<el-input v-model="queryParams.contactMobileNo" clearable placeholder="请输入联系人手机号" size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="新增时间">
						<el-date-picker v-model="createDateRange" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="," style="width: 100%" start-placeholder="开始时间" end-placeholder="结束时间"/>
					</el-form-item>
				</el-col>
			</el-row>
            <el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="审核通过时间">
						<el-date-picker v-model="checkPassTime" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="-" style="width: 100%" start-placeholder="开始时间" end-placeholder="结束时间"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="结算状态" prop="name">
						<el-select v-model="queryParams.setterStatus" placeholder="请选择结算状态" size="small" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option label="正常" value="1"></el-option>
							<el-option label="冻结" value="2"></el-option>
							<el-option label="分润冻结" value="4"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="分润代付开关" prop="name">
						<el-select v-model="queryParams.profitSwitch" placeholder="请选择分润代付开关" size="small" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option label="开启" value="1"></el-option>
							<el-option label="关闭" value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="返现代付开关" prop="name">
						<el-select v-model="queryParams.cashbackSwitch" placeholder="请选择返现代付开关" size="small" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option label="开启" value="1"></el-option>
							<el-option label="关闭" value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
            <el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="审核状态" prop="name">
						<el-select v-model="queryParams.status" placeholder="请选择审核状态" size="small" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option label="已创建" value="0"></el-option>
							<el-option label="审核失败" value="1"></el-option>
							<el-option label="待审核" value="2"></el-option>
							<el-option label="审核终止" value="3"></el-option>
							<el-option label="正常" value="4"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-permission="'AGENT_INFO_QUERY'">
                    查询
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd" v-permission="'AGENT_INFO_INSERT'">
                    新增子代
                </el-button>
				<el-button icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>
            </el-col>
        </el-row>
        <el-dialog
            :visible.sync="changeRole"
            width="400px">
            <div style="padding:10px 0 10px 20px"><el-radio v-model="userType" label="0">企业</el-radio></div>
            <div style="padding:10px 0 40px 20px"><el-radio v-model="userType" label="1">个人</el-radio></div>
            <el-row :gutter="10" class="mb8">
                <el-col :span="1.5">
                    <el-button type="primary"  @click="addAgent" >
                        新增
                    </el-button>
                    <el-button type="primary" @click="changeRole = false">
                        取消
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { ProfitManage } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			checkPassTime: [],
			showCustomForm: false,
			changeRole: false,
			userType:'1'
		}
	},
	mounted() {
	},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery(){
			this.queryParams = {};
			this.createDateRange = [];
			this.checkPassTime = [];
			this.commitChange();
		},
		handleAdd(){
			this.changeRole = true
		},
		addAgent(){
			this.changeRole = false
			this.$router.push({
				name:'agentInsert',
				query:{
					userType: this.userType
				}
			})
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出代理商查询吗", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				// 导出代理商查询列表
				let result = await ProfitManage.execlDownloadInfo.exportAgentInfoAgent(
					this.queryParams
				);
				console.log("exportAgentInfoAgent----->269", result);
				if (result.success) {
					// 判断success为true则调用下载
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			if(!this.createDateRange){
				this.createDateRange =[]
			}
			if(!this.checkPassTime){
				this.checkPassTime =[]
			}
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
			this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');
			let [startCheckPassTime, endCheckPassTime] = this.checkPassTime;
			this.queryParams.startCheckPassTime = startCheckPassTime && (startCheckPassTime + ' 00:00:00');
			this.queryParams.endCheckPassTime = endCheckPassTime && (endCheckPassTime + ' 23:59:59');
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = {...this.queryParams};
			this.$emit('on-change', params);
		},
	}
}
</script>

<style scoped>

</style>
